<template>

	<form @submit.prevent="forgotPassword()">
		<h2 class="mb-4 mt-5 text-center">Zabudnuté heslo</h2>
		
		<cmf-input :icon="mail" class="input" v-model="v$.form.email" :vBe="vBe" placeholder="Email" />

		<ion-button type="submit" expand="block" color="primary" fill="solid" shape="round">Odoslať kód</ion-button>
	</form>

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mail } from 'ionicons/icons'

export default {
	props: {
		navigateNext: {
			type: String,
			default: 'resetpassword'
		}
	},
	
	data() {
		return {
			v$: useVuelidate(),
			vBe: [],
			form: {},
			mail
		}
	},

	validations() {
		return {
			form:{
				email: {required, email},
			}
		}
	},

	methods: {
		async forgotPassword() {
			if (!(await this.v$.$validate())) return
			this.$wLoader.startLoading(this.$t('Sending code...'))

			try {
				await this.$store.dispatch('wAuth/forgotPassword', {email: this.form.email})
				this.$router.push({name: this.navigateNext, params: {email: this.form.email || ''}})
			} catch (error) {
				this.$wToast.error(error)
			}
			
			this.$wLoader.clearLoading()
		}
	},

	watch: {
		$route(to) {
			if (to.name == 'forgotpassword') {
				this.form = {}
				setTimeout(() => { this.v$.$reset() }, 10)
			}
		}
	}
}
</script>
